
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}


/**
 * 6~16位，不包含空格
 * @returns 
 */
export function loginPassworValidate(str) {
    return /^[^\s]{6,16}$/.test(str);
}


export function _isEmpty(input) {
    return String(input) === 'null' || String(input) === 'undefined' || input === '';
}

/**
 * 密码验证
 * 密码规则：6-16位字符，同时包括数字、大写字母组合；
 * @param {*} str 
 * @returns 
 */
export function passwordValidate(str) {
    const reg = /^(?=.*[A-Z])(?=.*[0-9]).{6,16}$/;
    return reg.test(str);
}
export const cnvCN = {
    'Cnv.Explore.Title': '拷贝数变化和肿瘤异质性',

    //cnv
    'Cnv.Select.Sample': '样本:',
    'Cnv.Select.Cells.To.test.CNV': '需要检测CNV的细胞:',
    'Cnv.Select.Select.Normal.Cells.As.Reference': '选择正常细胞作为参考:',
    'Cnv.Result.Detected.CNV': '检测到的CNV热图 ',
    'Cnv.Result.Detected.CNV.Tips': '热图可视化了细胞中检测到的拷贝数变异。基因沿染色体从左到右排序，红色代表扩增，蓝色代表缺失。',
    'Cnv.Result.CNV.Score': 'CNV 分数',
    'Cnv.Result.CNV.Score.Tips': 'CNV分数量化了拷贝数变异的水平。分数越高表示检测到的拷贝数变异水平越大。',
    'Cnv.Download.CNS.Score.Table': '下载CNV分数结果',
    'Cnv.Choose.Version': '选择CNV版本',
    'Cnv.Retain.Cell.Type.Label': '留细胞类型标签',
    'Cnv.Number.Of.Hierarchical.Clusters': '层次聚类数目',

    Heatmap: {
        intercnv: '热图可视化了细胞中检测到的拷贝数变异。基因沿染色体从左到右排序，红色代表扩增，蓝色代表缺失。',
        intercnvR: '热图可视化了细胞中检测到的拷贝数变异。参考细胞（正常细胞）的表达值绘制在上方热图中，目标细胞（肿瘤细胞）绘制在下方热图中。基因在整个染色体上从左到右排列。其中染色体扩增显示为红色，而染色体缺失显示为蓝色。',
    },
    cnvScope: {
        intercnv: 'CNV分数量化了拷贝数变异的水平。分数越高表示检测到的拷贝数变异水平越大。',
        intercnvR: '基于检测到的拷贝数变异结果，对拷贝数的变异程度进行量化（CNV 分数）。CNV 分数越大，代表该细胞类型的CNV变异程度越大。',
    },
    'CNV.Specific.CNV.Region': '特异性CNV区段',
    'CNV.Specific.CNV.Region.Tips': '按染色体排列的基因CNV平均值变化曲线绘制在上图，红色为扩增，蓝色为缺失。下图为在分析的 cluster 中依据大于所划分的阈值发生CNV变化的细胞所占比例。下图展示了分析的cluster中超过预设阈值的CNV变化的细胞所占的比例。',
    'CNV.Arm.Level.Analysis': '长短臂分析',
    'CNV.Arm.Level.Analysis.Tips': 'CNV长短臂分析指的是对染色体的长臂（q arm）和短臂（p arm）上的拷贝数变异进行识别和分析。纵坐标是以染色体长短臂表示的扩增缺失状态（例如：1q- 代表1号染色体长臂缺失）。横坐标为不同cluster，颜色代表扩增缺失的程度。',
    'CNV.Species.Tips': '仅限人类和小鼠物种。',

    //ITH
    'ITH.Compare.ITH.By': '比较ITH',
    'ITH.Cell.Type': '细胞类型',
    'ITH.Sample.Intra.Tumoral.Heterogeneity': '样本肿瘤异质性',
    'ITH.Sample.Intra.Tumoral.Heterogeneity.Tips': 'ITH分数描述了每个选定样本中肿瘤的瘤内异质性，使用了新格元开发的算法。',
};

export const cnvUS = {
    'Cnv.Explore.Title': 'Explore CNV and malignancy',

    //cnv
    'Cnv.Select.Sample': 'Sample:',
    'Cnv.Select.Cells.To.test.CNV': 'Cells to test CNV:',
    'Cnv.Select.Select.Normal.Cells.As.Reference': 'Select normal cells as reference:',
    'Cnv.Result.Detected.CNV': 'Detected CNV heatmap',
    'Cnv.Result.Detected.CNV.Tips': 'The heatmap visualizes detected CNV in the cells. Genes are ranked from left to right along the chromosome. Red indicates amplification. Blue indicates deletion.',
    'Cnv.Result.CNV.Score': 'CNV score',
    'Cnv.Result.CNV.Score.Tips': 'the score quantifies the level of CNV. The higher score indicates the greater level of CNV detected.',
    'Cnv.Download.CNS.Score.Table': 'Download CNV score table',
    'Cnv.Choose.Version': 'Choose CNV version',
    'Cnv.Retain.Cell.Type.Label': 'Retain cell type label',
    'Cnv.Number.Of.Hierarchical.Clusters': 'Number of hierarchical clusters',

    Heatmap: {
        intercnv: 'The heatmap visualizes detected CNV in the cells. Genes are ranked from left to right along the chromosome. Red indicates amplification. Blue indicates deletion.',
        intercnvR: 'The heatmap visualizes detected copy number variation (CNV) in the cells. The expression of reference cells (normal cells) are plotted in the upper heatmap, while the target cells (cancer cells) are plotted in the lower heatmap. Genes are ranked from left to right along the chromosome. Red indicates amplification. Blue indicates deletion.',
    },
    cnvScope: {
        intercnv: 'The score quantifies the level of CNV. The higher score indicates the greater level of CNV detected.',
        intercnvR: 'Based on the detected copy number variation (CNV) results, the score of CNV is quantified. A higher CNV score indicates a greater degree of CNV variation in the cell type.',
    },
    'CNV.Specific.CNV.Region': 'Specific CNV region',
    'CNV.Specific.CNV.Region.Tips': 'The upper graph plots the average CNV values of genes organized according to their chromosomal arrangement. Red indicates amplification. Blue indicates deletion. The lower graph shows the proportion of cells within a specific cluster that exhibit CNV changes exceeding the set threshold. ',
    'CNV.Arm.Level.Analysis': 'CNV arm-level analysis',
    'CNV.Arm.Level.Analysis.Tips': 'CNV arm-level analysis refers to the identification and analysis of copy number variations on the long arms (q arms) and short arms (p arms) of chromosomes. The vertical axis represents the amplification or deletion status by the chromosome arms (for example: 1q- represents the deletion of the long arm of chromosome 1). The horizontal axis represents different clusters, with colors indicating the degree of amplification or deletion.',
    'CNV.Species.Tips': 'Only human and mice species are supported.',


    //ITH
    'ITH.Compare.ITH.By': 'Compare ITH by',
    'ITH.Cell.Type': 'Cell type',
    'ITH.Sample.Intra.Tumoral.Heterogeneity': 'Sample Intra-tumoral heterogeneity',
    'ITH.Sample.Intra.Tumoral.Heterogeneity.Tips': 'The ITH score describes the intra-tumoral heterogeneity of tumors from each selected sample using an algorithm developed by Singleron.',
};
export const cellularCompositionCN = {
    'Cellular.Composition.Explore.Title': '细胞占比',
    'Cellular.Composition.View.Cellular.Composition': '查看细胞占比',
    'Cellular.Composition.Download.Cells.Table': '下载细胞占比表格',
    'Cellular.Composition.View.Stacked.Bar': '查看指定样本的stacked bar plot',
    'Cellular.Composition.Compare.Selected.Cells': '比较组间细胞占比平均值',
    'Cellular.Composition.Compare.Cells.Tips': '如果所选样本组小于3个样本，无法绘制平均值box plot。',
    'Cellular.Composition.Compare.Choose': '选择',
    'Cellular.Composition.Compare.From.Groups': '来自2个分组',
    'Cellular.Composition.All.Samples': '所有样本',
    'Cellular.Composition.Ro.E.Analysis': 'Ro/e分析 (卡方检验)',
    'Cellular.Composition.Ro.E.Analysis.Tips': 'Ro/e计算失败，因为观察值与期望值之间p值大于0.05。',
};

export const cellularCompositionUS = {
    'Cellular.Composition.Explore.Title': 'Explore Cellular Composition',
    'Cellular.Composition.View.Cellular.Composition': 'View cellular composition',
    'Cellular.Composition.Download.Cells.Table': 'Download cells per group table',
    'Cellular.Composition.View.Stacked.Bar': 'View stacked bars of selected samples',
    'Cellular.Composition.Compare.Selected.Cells': 'Compare group average of select cells',
    'Cellular.Composition.Compare.Cells.Tips': 'If the selected group has less than 3 samples, the box plot cannot be plotted',
    'Cellular.Composition.Compare.Choose': 'Choose',
    'Cellular.Composition.Compare.From.Groups': 'from 2 groups',
    'Cellular.Composition.All.Samples': 'All sample',
    'Cellular.Composition.Ro.E.Analysis': 'Ro/e analysis (Chi-square test)',
    'Cellular.Composition.Ro.E.Analysis.Tips': 'The Ro/e calculation failed because the p-value between the observed and expected values is greater than 0.05.',

};
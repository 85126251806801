export const importDataCN = {
    'Import.Data.Tabs.Expression.Data': '表达数据',
    'Import.Data.Tabs.Custom.Data': '已注释数据集',
    'Import.Data.Agreement.Txt.Black': '我已阅读并同意',
    'Import.Data.Agreement.Txt.Blue': ' 用户人类遗传资源管理免责声明',
    'Import.Data.View.Format.Descriptions': '查看数据格式描述',

    'Import.Data.Custom.Dataset.H5ad.Tips': '暂时只允许上传新格元AnnData文件。',
    'Import.Data.Custom.Genome.Converter.Optional': '基因组转换（可选）',
    'Import.Data.Custom.Optional.Tips': '当您需要进行通路富集分析和细胞相互作用分析时，请根据基因组相似性将您的数据基因组转换为人类或小鼠基因组。',
    'Import.Data.Custom.Submit.Successfully': '成功提交',
    'Import.Agreement.Txt.Message': '在上传任何数据之前，请确保仔细阅读免责声明，并确认您已经充分理解并同意其中的所有内容。',
    'Import.Data.Uploaded.Files': '已上传文件',
    'Import.Data.Agreement.Tips': '在上传任何数据之前，请确保仔细阅读免责声明，并确认您已经充分理解并同意其中的所有内容。',

    'Import.Data.Sparse.Matrices': '将三个文件放入文件夹中并压缩为.zip或.tar或.tar.gz。',
    'Import.Data.Full.Matrices': '对于一个TSV矩阵文件，第一行是barcodes，第一列是gene symbols。',
    'Import.Data.AnnData': '只可以上传新格元规定格式的AnnData文件。',
    'Import.Data.Sparse.Matrices.Name': '稀疏矩阵',
    'Import.Data.Full.Matrices.Name': '完全矩阵',
    'upload.File.tip1': '请确保每个上传文件中',
    'upload.File.tip2': '仅包含一个样本。',
};
export const importDataUS = {
    'Import.Data.Tabs.Expression.Data': 'Expression Matrix',
    'Import.Data.Tabs.Custom.Data': 'Annotated Dataset',
    'Import.Data.Agreement.Txt.Black': 'I have read and agree to the',
    'Import.Data.Agreement.Txt.Blue': 'User Genetic Resource Management Liability Disclaimer',
    'Import.Data.View.Format.Descriptions': 'View format descriptions',

    'Import.Data.Custom.Dataset.H5ad.Tips': 'Only Singleron AnnData files are allowed for uploaded.',
    'Import.Data.Custom.Genome.Converter.Optional': 'Genome converter （optional）',
    'Import.Data.Custom.Optional.Tips': 'When you need to perform pathway enrichment analysis and cell interaction analysis, please convert your data genome to human or mouse genome based on genomic similarity.',
    'Import.Data.Custom.Submit.Successfully': 'Submit successfully',
    'Import.Agreement.Txt.Message': 'Before uploading any data, please make sure to carefully read the disclaimer and confirm that you have fully understood and agreed to all the contents.',
    'Import.Data.Uploaded.Files': 'Uploaded files',
    'Import.Data.Agreement.Tips': 'Before uploading any data, please make sure to carefully read the disclaimer and confirm that you have fully understood and agreed to all the contents.',

    'Import.Data.Sparse.Matrices': 'Place the three files into the folder and compress them into a .zip, .tar, or .tar.gz file.',
    'Import.Data.Full.Matrices': 'For a TSV matrix file, the first row should be barcodes, the first column should be gene symbols. ',
    'Import.Data.AnnData': 'Only Singleron AnnData files are allowed for uploading.',

    'Import.Data.Sparse.Matrices.Name': 'Sparse matrices',
    'Import.Data.Full.Matrices.Name': 'Full matrices',
    'upload.File.tip1': 'Please ensure that each uploaded file contains',
    'upload.File.tip2': ' only one sample.',
};
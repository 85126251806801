export const tutorialsCN = {
    'Tutorials.Homepage.Overview': '主页介绍',
    'Tutorials.Upload.Matrix': '上传矩阵',
    'Tutorials.Initiate.Analysis': '启动分析',
    'Tutorials.See.More.Details': '点击查看更多说明',
    'Tutorials.Got.It': '好的！',
    'Tutorials.Show.Again': '不再提示',
    'Tutorials.Step3.Title': '如何上传单样本矩阵?',
    'Tutorials.Step4.Title': '如何启动本地数据整合分析？',
};


export const tutorialsUS = {
    'Tutorials.Homepage.Overview': 'Homepage Overview',
    'Tutorials.Upload.Matrix': 'Upload matrix',
    'Tutorials.Initiate.Analysis': 'Initiate analysis',
    'Tutorials.See.More.Details': 'Click to see more details',
    'Tutorials.Got.It': 'Got it !',
    'Tutorials.Show.Again': 'Dont’t show this again',
    'Tutorials.Step3.Title': 'How to upload Single-Sample Matrix?',
    'Tutorials.Step4.Title': 'How to start local data integration analysis?',

};
<template>
  <div class="step-one-container">
        <div class="show-content">
          <h2> {{ $t('Tutorials.Step4.Title') }}</h2>
          <div v-if="lang === 'zh-CN'">
            <ul>
              <li>
                <img src="@/assets/tutorials/Initiate1.png" alt="">
                <div class="desc">
                  <h3>第1步：</h3>
                  <p>从菜单栏进入 <strong>启动分析-整合本地数据</strong> 板块。</p>
                  </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate2.png" alt="">
                <div class="desc">
                  <h3>第2步：</h3>
                  <p>从<strong>选择已上传的文件</strong>列表中，勾选所需样本数据。需确保文件包含物种信息，否则无法选择。</p>
                  <h3>第3步：</h3>
                  <p>
                    数据引入后，在<strong>编辑样本信息</strong>表格中，添加样本名称和样本分组。
                  </p>
                  <h3>第4步：</h3>
                  <p>
                    输入任务名称。
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate3.png" alt="">
                <div class="desc">
                  <h3>第5步：</h3>
                  <p>
                    选择一个注释参考，用于CellID自动注释。
                  </p>
                  <h3>第6步：</h3>
                  <p>
                    请根据基因组相似性，选择一个参考物种。若所选参考物种与数据物种不同，自动注释将基于同源转化后基因注释。
                  </p>
                  <h3>第7步：</h3>
                  <p>
                    选择自动注释所需的器官和组织。
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate4.png" alt="">
                <div class="desc">
                  <h3>第8步：</h3>
                  <p>
                    根据需要调整过滤细胞的阈值，包括表达基因范围、UMI范围和线粒体含量。
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate5.png" alt="">
                <div class="desc">
                  <h3>第9步：</h3>
                  <p>
                    选择是否去除背景噪音和去除双胞污染。默认检测双胞率，勾选后将执行去除双胞。
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate6.png" alt="">
                <div class="desc">
                  <h3>第10步：</h3>
                  <p>
                    调整聚类分辨率和高可变基因数量。并选择是否去除批次效应。
                  </p>
                  <h3>第11步：</h3>
                  <p>
                    提交分析，可在<img src="@/assets/tutorials/my_analyses_CN.png" alt=""> 的
                    <img src="@/assets/tutorials/cloud_CN.png" alt="">模块查看结果等。
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <ul>
              <li>
                <img src="@/assets/tutorials/Initiate1.png" alt="">
                <div class="desc">
                  <h3>Step 1:</h3>
                  <p>
                    Go to the <strong>Initiate Analysis - Integrate Local Data</strong> section from the top menu bar.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate2.png" alt="">
                <div class="desc">
                  <h3>Step 2:</h3>
                  <p>
                    From the list of uploaded files, select the required sample data. Ensure that the file contains species information; otherwise, it cannot be selected.
                  </p>
                  <h3>Step 3:</h3>
                  <p>
                    Once the data is imported, add sample names and sample groups in the <strong>Edit Sample Info</strong> table.
                  </p>
                  <h3>Step 4:</h3>
                  <p>
                    Enter the job name.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate3.png" alt="">
                <div class="desc">
                  <h3>Step 5:</h3>
                  <p>
                    Choose an annotation reference for automatic CellID annotation.
                  </p>
                  <h3>Step 6:</h3>
                  <p>
                    Choose a reference species based on genomic similarity. If the selected reference species is different from the data species, the automatic annotation will be based on homologous genes.
                  </p>
                  <h3>Step 7:</h3>
                  <p>
                    Select the organs and tissues required for automatic annotation.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate4.png" alt="">
                <div class="desc">
                  <h3>Step 8:</h3>
                  <p>
                    Adjust the thresholds for filtering cells as needed, including the expressed gene range, UMI range, and mitochondrial content.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate5.png" alt="">
                <div class="desc">
                  <h3>Step 9:</h3>
                  <p>
                    Choose whether to remove ambient RNA and doublet contamination. By default, doublet rates are detected; checking this option will remove doublets.
                  </p>
                </div>
              </li>
              <li>

                <img src="@/assets/tutorials/Initiate6.png" alt="">
                <div class="desc">
                  <h3>Step 10:</h3>
                  <p>
                    Adjust the clustering resolution and the number of highly variable genes, and choose whether to remove batch effects.
                  </p>
                  <h3>Step 11:</h3>
                  <p>
                    Submit the analysis. You can view the results in the <img src="@/assets/tutorials/my_analyses_EN.png" alt=""> -
                    <img src="@/assets/tutorials/cloud_EN.png" alt="">Module.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: "stepOne",
  components: {
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.step-one-container {
  .show-content {
    padding-bottom: 10px;
  }
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin-top: 30px;
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      margin-top: 25px;
      img {
        height: fit-content;
      }
      &:first-child {
        margin-top: 15px;
      }
      .desc {
        margin: 5px 10px 0 30px;
        h3 {
          font-weight: bold;
          font-size: 14px;
          color: $main-color;
          white-space: nowrap;
          &:nth-child(n+2) {
            margin-top: 20px;
          }
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          line-height: 20px;

          img {
            margin: 0 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
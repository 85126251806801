<template>
  <div class="basic-container">
    <div class="row-wrapper">
      <div class="router-box">
        <ul>
          <li
              v-for="(obj, index) in list"
              :key="index"
              :class="{'active': currentShowModule === obj.value}"
              @click.stop="handleShowCurrentInfo(obj)">
            {{ $t(obj.label) }}
          </li>
        </ul>
      </div>
      <div class="right-box">
        <slot name="content"></slot>
      </div>
    </div>
  <div class="btn-wrapper">
    <el-button type="text" @click="handleSeeMore">{{ $t('Tutorials.See.More.Details') }}</el-button>
    <el-button type="primary" class="btn-got" @click="handleGotIt">{{ $t('Tutorials.Got.It') }}</el-button>
    <el-checkbox>{{ $t('Tutorials.Show.Again') }}</el-checkbox>
  </div>
  </div>
</template>

<script>
export default {
  name: "basicLayout",
  data() {
    return {
      list: [{
        label: 'Tutorials.Homepage.Overview',
        value: 'homepage',
        step: 'two',
      },{
        label: 'Tutorials.Upload.Matrix',
        value: 'upload',
        step: 'three',
      },{
        label: 'Tutorials.Initiate.Analysis',
        value: 'initiate',
        step: 'four',
      }],
      currentShowModule: 'homepage',
    };
  },
  props: {},
  methods: {
    resetRouter() {
      this.currentShowModule = 'homepage';
    },
    // 更多细节
    handleSeeMore() {
      this.$emit('sendSeeMore', this.currentShowModule);
    },

    // 已了解 - 关闭弹窗
    handleGotIt() {
      this.$emit('closeDialog');
    },

    // 点击侧边栏， 下一步
    handleShowCurrentInfo(obj) {
      this.currentShowModule = obj.value;
      this.$emit('sendShowCurrentInfo', obj.step);
    },
  },
};
</script>

<style scoped lang="scss">
.basic-container {
  .row-wrapper {
    display: flex;
    .router-box {
      ul {
        //padding: 0 20px;
        padding-top: 120px;
        width: 190px;
        li {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          white-space: nowrap;
          cursor: pointer;
          height: 46px;
          //width: 190px;
          margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            width: 200px;
            background: $main-color;
            //box-shadow: 6px 7px 17px 0px rgba(32,70,109,0.2);
            border-radius: 8px;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            margin-left: -20px;
          }
        }
      }
    }
    .right-box {
      border-left: 2px solid #F2F2F2;
      padding: 0 25px;
      height: 600px;
      overflow-y: auto;
    }
  }
  .btn-wrapper {
    border-top: 2px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;
    .btn-got {
      width: 250px;
      height: 40px;
      background: $main-color;
      border-radius: 20px;
    }
    .el-checkbox {
      opacity: 0;
    }
  }
}
</style>
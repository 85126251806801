import { URL_PREFIX } from '@/const/const.js';
export default class Server {

    //判断ip所属国家
    static API_GET_IP_ADDRESS = URL_PREFIX + "/account/criteriaIpAddress";

    /*获取验证码*/
    // static DATABASE_API_USER_REQ_GET_VERIFY = URL_PREFIX + "/public/getCheckCode";

    //登录接口
    static API_POST_USER_LOGIN = URL_PREFIX + "/account/sign-up";

    //注册接口
    static DATABASE_API_USER_REG_URL = URL_PREFIX + "/account/reg";

    // 校验用户名接口
    static DATABASE_API_USER_CHECK_USERNAME = URL_PREFIX + '/account/reg/check_username';

    // 校验邮箱接口
    static DATABASE_API_USER_CHECK_EMAIL = URL_PREFIX + '/account/reg/check_email';

    // 校验手机号接口
    static DATABASE_API_USER_CHECK_PHONE = URL_PREFIX + '/account/reg/check_phone';

    // 发送邮箱获取验证码
    static DATABASE_API_USER_SEND_MESSAGE = URL_PREFIX + '/account/sendMessage';

    // 退出登录
    static API_GET_USER_LOGOUT = URL_PREFIX + '/account/sign-out';

    // 获取用户基本信息
    static API_GET_USER_INFO = URL_PREFIX + '/account/info';

    // 忘记密码
    static USER_DATABASE_API_GET_FORGOT_PASSWORD = URL_PREFIX + "/account/beforeLogin/password/update";

}
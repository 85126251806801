export const projectFilesCN = {
    'My.Analyses.Project.RawData.Files.Title': '原始数据',
    'My.Analyses.Project.RawData.Files.Description': '由SCOPE-chip®生成的paried-end FASTQ文件。',
    'My.Analyses.Project.Celescope.Files.Title': 'CeleScope数据',
    'My.Analyses.Project.Celescope.Files.Description': 'CeleScope®的输出文件。',
    'My.Analyses.Project.AnnData.Files.Title': 'AnnData',
    'My.Analyses.Project.AnnData.Files.Description': '单样本h5ad文件线上可视化。',
    'My.Analysis.Project.Download': '下载样本矩阵文件。',
    'My.Analysis.Project.Report': '下载样本矩阵质控报告。',
    'My.Analysis.Project.Parse': '将矩阵数据转换为H5AD格式，以进行整合分析。',
    'My.Analysis.Project.Edit': '编辑样本物种。',
    'My.Analyses.Project.Action.Convert.Genome.Tips': '根据Ensembl基因ID将当前基因组转换为指定的基因组。',
};
export const projectFilesUS = {
    'My.Analyses.Project.RawData.Files.Title': 'Raw Data',
    'My.Analyses.Project.RawData.Files.Description': 'Paried-end FASTQ files generated by SCOPE-chip®.',
    'My.Analyses.Project.Celescope.Files.Title': 'CeleScope Data',
    'My.Analyses.Project.Celescope.Files.Description': 'Output files from CeleScope®.',
    'My.Analyses.Project.AnnData.Files.Title': 'AnnData',
    'My.Analyses.Project.AnnData.Files.Description': 'H5ad files of each sample for online visualisation.',
    'My.Analysis.Project.Download': 'Download the sample matrix file.',
    'My.Analysis.Project.Report': 'Download the sample matrix QC report.',
    'My.Analysis.Project.Parse': 'Convert matrix data into H5AD format for integration analysis. ',
    'My.Analysis.Project.Edit': 'Edit the sample species.',
    'My.Analyses.Project.Action.Convert.Genome.Tips': 'Convert the current genome to a specified genome based on Ensembl gene ID.',
};

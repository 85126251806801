<template>
  <div class="export-data-container">
    <!--:href="aUrl"-->
    <a class="download-info" @click="downloadFileByClick">
      <img src="@/assets/viewData/download.png" alt=""/>
      <span class="export-data">
        {{ $t('tf.Export.Data') }}
        <span v-show="selectedJobName">: </span>
      </span>
      <span v-show="selectedJobName">
        {{ selectedJobName }}
      </span>
      <explain-information class="express-tip" popoverTip="tf.Export.Data.Tips" />
    </a>
  </div>
</template>

<script>
import { downloadFromS3 } from "@/utils/download";
import ExplainInformation from "@/components/Popover/ExplainInformation";
import jsZip from "jszip";
export default {
  name: "ExportData",
  components: {
    ExplainInformation,
  },
  props: {
    selectedJobName: {
      type: String,
      default: '',
    },
    aUrl: {
      type: [String, Object],
      default: null,
    },
    outFileName: {
      type: String,
      default: '',
    },
    isS3: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    // aUrl: {
    //   handler(newVal) {
    //     if(!newVal || !this.isS3) {return;}
    //     this.downloadData();
    //   },
    //   deep: true,
    // },
  },
  methods: {
    downloadData(fileUrl) {
      downloadFromS3(fileUrl, this.outFileName);
    },
    downloadFileByClick(e) {
      e.stopPropagation(); // 阻止事件向上冒泡
      if(!this.isS3) {
        this.downloadCsv();
        return;
      }
      this.$emit('sendDownloadFlag');
    },
    downloadCsv() {
      const zip = new jsZip(); //创建一个压缩包对象
      zip.file(`count.csv`, this.aUrl['aUrl']); //往压缩包里加一个csv文件
      zip.file(`percent.csv`, this.aUrl['bUrl']); //往压缩包里加一个csv文件
      //下载压缩包
      zip.generateAsync({ //压缩包转blob
        type: 'blob',
      }).then(content => {
        const eleLink = document.createElement('a');
        eleLink.download = this.outFileName;
        eleLink.style.display = 'none';
        eleLink.href = URL.createObjectURL(content); // 下载内容转变成blob地址
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.export-data-container {
  .download-info {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: $main-color;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    padding: 5px 10px;
    min-height: 30px;
    //line-height: 30px;
    line-height: 1;
    cursor: pointer;
    .export-data {
      white-space: nowrap;
      margin-right: 3px;
    }
    .express-tip {
      margin-left: 8px;
    }
  }
  img {
    width: 13px;
    height: 13px;
    margin-right: 8px;
  }
}
</style>
<template>
  <div class="step-one-container">
        <div class="show-content">
          <h2> {{ $t('Tutorials.Step3.Title') }}</h2>
          <div v-if="lang === 'zh-CN'">
            <ul>
              <li>
                <img src="@/assets/tutorials/Upload1.png" alt="">
                <div class="desc">
                  <h3>第1步：</h3>
                  <p>从菜单栏进入 <img src="@/assets/tutorials/import_data_CN.png" alt="">模块。</p>
                  <h3>第2步：</h3>
                  <p>根据数据类型，选择对应格式上传单样本矩阵。上传成功后，文件进度条会显示"upload success",表示文件已上传</p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Upload2.png" alt="">
                <div class="desc">
                  <h3>第3步：</h3>
                  <p>
                    点击 <img src="@/assets/tutorials/next_CN.png" alt="">，从下拉列表中选择每个矩阵的物种。如果列表中没有所需物种，选择NA。
                  </p>
                  <h3>第4步：</h3>
                  <p>
                    点击 <img src="@/assets/tutorials/submit_CN.png" alt="">。提交数据后，数据将开始解析成最终可用格式。
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Upload3.png" alt="">
                <div class="desc">
                  <h3>第5步：</h3>
                  <p>
                    从上方菜单栏进入到 <img src="@/assets/tutorials/my_analyses_CN.png" alt="">页面。选择
                    <img src="@/assets/tutorials/upload_file_CN.png" alt="">页签，查看上传的矩阵文件列表。数据
                    上传后需要解析，列表中数据状态展示为"Completed"，代表矩阵上传并且解析成功，可以用于整合分析。
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <ul>
              <li>
                <img src="@/assets/tutorials/Upload1.png" alt="">
                <div class="desc">
                  <h3>Step 1:</h3>
                  <p>Go to the <img src="@/assets/tutorials/import_data_EN.png" alt=""> module from the top menu bar.</p>
                  <h3>Step 2:</h3>
                  <p>
                    Choose the appropriate data type to upload the <b>single-sample</b> matrix. Once uploaded successfully,
                    a message saying "upload success" will
                    appear, indicating the file has been uploaded.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Upload2.png" alt="">
                <div class="desc">
                  <h3>Step 3:</h3>
                  <p>
                    Click <img src="@/assets/tutorials/next_EN.png" alt=""> and select the species for each matrix from the dropdown list. If
                    the required species is not listed, select NA(not in options).
                  </p>
                  <h3>Step 4:</h3>
                  <p>
                    Click <img src="@/assets/tutorials/submit_EN.png" alt="">. After submitting the data, it will begin to processed into the final usable format.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Upload3.png" alt="">
                <div class="desc">
                  <h3>Step 5:</h3>
                  <p>
                    Go to the <img src="@/assets/tutorials/my_analyses_EN.png" alt=""> module from the top menu
                    bar, and select the <img src="@/assets/tutorials/upload_file_EN.png" alt=""> tab to view the list of uploaded matrix files.
                    After the data is uploaded, it needs to be parsed. When the status shows as "Completed", it
                    means the matrix has been successfully uploaded and parsed, and is ready for integrated analysis.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: "stepOne",
  components: {},
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.step-one-container {
  .show-content {
    margin-bottom: 10px;
  }
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin-top: 30px;
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      margin-top: 25px;
      img {
        height: fit-content;
      }
      &:first-child {
        margin-top: 15px;
      }
      .desc {
        margin: 5px 10px 0 30px;
        h3 {
          font-weight: bold;
          font-size: 14px;
          color: $main-color;
          white-space: nowrap;
          &:nth-child(n+2) {
            margin-top: 20px;
          }
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          line-height: 20px;

          img {
            margin: 0 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
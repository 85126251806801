import { OPER_AUTH_TYPE } from "@/const/userOperAuth";
import store from "@/store";
export const viewEditInfo = {
    cellFilteringInfo: [{
        label: 'Analysis.Expressed.Gene.Range',
        value: 'expressedGeneRange',
    },{
        label: 'Analysis.Expressed.UMI.Range',
        value: 'expressedUmiRange',
    },{
        label: 'Analysis.Mitochondrial.Content',
        value: 'mitochondrialContent',
    }],
    CellQualityAdjustments: [{
        label: 'Analysis.Cell.Quality.Ambient',
        value: 'ambientRnaRemoval',
    },{
        label: 'Analysis.Cell.Quality.Doublet.DR',
        value: 'doubletDetectionAndRemoval',
    }],
    analysisOptions: [
    {
        label: 'Analysis.Clustering.Resolution',
        value: 'clusterResolution',
    },{
        label: 'Analysis.Number.Of.Hvg',
        value: 'numberOfHighlyVariableGenes',
    },{
        label: 'Initiate.Remove.Cell.Cycle.Effects',
        value: 'removeCellCycleEffects',
    },{
        label: 'Initiate.Scale.Large.Data',
        value: 'scaleLargeData',
    },{
        label: 'My.Analysis.Version',
        value: 'version',
    },{
        label: 'Analysis.Batch.Effect.Removal',
        value: 'batchEffectRemoval',
    }],
    annotationOptions: [{
        label: 'Analysis.Annotation.Type',
        value: 'chooseAnnotationType',
    },{
        label: 'Analysis.Annotation.Algorithm1',
        value: 'algorithm',
    },{
        label: 'Analysis.Annotation.Reference1',
        value: 'reference',
    },{
        label: 'Initiate.Analysis.Annotation.Options.Refrence.Species',
        value: 'species',
    },{
        label: 'Analysis.Annotation.Reference.Tissue',
        value: 'referenceTissue',
    }],
    childAnnotationOptions: [{
        label: 'Analysis.Annotation.Algorithm1',
        value: 'algorithm',
    },
    
    {
        label: 'Analysis.Annotation.Reference1',
        value: 'reference',
    },
    {
        label: 'Analysis.Annotation.Refrence.Species',
        value: 'annotationSpecies',
    },
    {
        label: 'Analysis.Cell.Type.Reference',
        value: 'cellTypeReference',
    }],
    childAnalysisOptions: [
    {
        label: 'Annotation.Extract.Re.Dimensionality.Reduction',
        value: 'reDimensionalityReduction',
    },{
        label: 'Initiate.Scale.Large.Data',
        value: 'onlyScaleHighlyVariableGenes',
    },{
        label: 'Analysis.Clustering.Resolution',
        value: 'clusteringResolution',
    },{
        label: 'Analysis.Number.Of.Hvg',
        value: 'numberOfHighlyVariableGenes',
    },{
        label: 'My.Analysis.Version',
        value: 'version',
    },{
        label: 'Analysis.Batch.Effect.Removal',
        value: 'batchEffectRemoval',
    }],
    other: [{
        label: '',
        value: 'taskMode',
    },{
        label: 'Analysis.Sub.Clusters',
        value: 'clusters',
    },{
        label: 'Analysis.Sub.Samples',
        value: 'samples',
    }],
};
const keyword = [{
    label: 'Integrated.Original.Analysis.ID',
    value: 'originalAnalysisId',
},{
    label: 'Integrated.Original.Job.Name',
    value: 'originalAnalysisName',
},{
    label: 'Integrated.Analysis.ID',
    value: 'analysisId',
},{
    label: 'My.Analyses.Job.Name',
    value: 'jobName',
},{
    label: 'Analysis.Subset',
    value: 'subset',
}];
export const viewJobInfo = {
    user_cell_score_analysis_task: keyword,
    user_enrich_one_vs_one_analysis_step1_task: keyword,
    user_monocle2_trajectory_analysis_task: keyword,
    user_trajectory_analysis_step1_task: keyword,
    user_cell_communication_analysis_step1_task: keyword,
    user_cnv_infercnvpy_analysis_task: keyword,
    user_cnv_ith_analysis_task: keyword,
    user_pyscenic_analysis_step1_task: keyword,
};

export const paramsAlias = {
    user_monocle2_trajectory_analysis_task: 'runmonocle2_parameters',
    user_cnv_infercnvpy_analysis_task: 'run_cnv_parameters',
    user_cnv_ith_analysis_task: 'run_ITH_parameters',
    user_enrich_one_vs_one_analysis_step1_task: '',
    user_pyscenic_analysis_step1_task: 'run_pyscenic_parameters',
};
export const alias = {
    user_cell_score_analysis_task: 'Integrated.Task.Type.Gene.Set.Enrichment',
    user_enrich_one_vs_one_analysis_step1_task: 'Integrated.Task.Type.Deg.Enrichment',
    user_monocle2_trajectory_analysis_task: 'ExploreTabsName.Trajectory.Analysis',
    user_trajectory_analysis_step1_task: 'ExploreTabsName.Trajectory.Analysis',
    user_cell_communication_analysis_step1_task: 'ExploreTabsName.Cell.Interactions',
    user_cnv_infercnvpy_analysis_task: 'ExploreTabsName.CNV.Analysis1',
    user_cnv_ith_analysis_task: 'ExploreTabsName.ITH.Analysis',
    user_pyscenic_analysis_step1_task: 'tf.Tab.title',
};
// 提示信息
export const tips = {
    // 删除按钮提示信息
    deleteTip: 'My.Analyses.Delete.Tips',
    // 暂停按钮提示信息
    suspendTips: 'My.Analyses.Suspend.Tips',
    uploadedDeleteTips: 'My.Analyses.Uploaded.Delete.Tips',
};

export const resultFileHeader = [{
    label: 'My.Analyses.Job.Name',
    value: 'jobName',
    sortable: 'custom',
},{
    label: 'My.Analyses.File.Name',
    value: 'filename',
},{
    label: 'My.Analyses.File.Size',
    value: 'filesize',
},{
    label: 'My.Analyses.Produced.On',
    value: 'producedOn',
    sortable: 'custom',
},{
    label: 'My.Analyses.Storage.Time',
    value: 'storageTime',
}];
export const projectFileHeader = [{
    label: 'My.Analyses.File.Name',
    value: 'filename',
},{
    label: 'My.Analyses.Project.name',
    value: 'projectName',
},{
    label: 'Analysis.Annotation.Species',
    value: 'species',
},{
    label: 'My.Analyses.Sample.ID',
    value: 'sampleName',
},{
    label: 'My.Analyses.File.Size',
    value: 'filesize',
},{
    label: 'My.analyses.Format',
    value: 'format',
},{
    label: 'My.Analyses.Uploaded.On',
    value: 'uploadedOn',
}];

export const customTableHeader = [{
    label: 'My.Analyses.File.Name',
    value: 'fileName',
    sortable: 'custom',
},{
    label: 'My.Analyses.File.Size',
    value: 'fileSize',
},{
    label: 'My.analyses.Format',
    value: 'format',
},{
    label: 'My.Analyses.Uploaded.On',
    value: 'uploadedOn',
    sortable: 'custom',
},{
    label: 'My.analyses.State',
    value: 'state',
    className: 'blue',
    clickBtn: true,
}];

export const uploadedFileType = {
    ANALYSIS_MATRIX_FULL: 'Full matrices',
    ANALYSIS_MATRIX_FEATURE: 'Feature_barcode matrices',
    ANALYSIS_MATRIX_H5: 'h5',
    ANALYSIS_MATRIX_H5AD: 'h5ad',
    ANALYSIS_MATRIX_LOOM: 'loom',
    rds: 'rds',
    h5ad: 'h5ad',
};

export const integrateAlias = {
    IntegrateLocal: '/integrateLocalData',
    sesdatasets: '/integrateSynEcoSysData',
    sesdatasetslocal: '/integrateLocalAndSynEcoSys',
};

// Initiate Analysis区域跳转路由
export const initiateAnalysis = [{
    name: 'Menu.List.Integrate.Local.Data',
    image: require("@/assets/myanalyzes/local.png"),
    path: '/integrateLocalData',
    isDisabled: store.getters.isDisabled1(OPER_AUTH_TYPE.integrateLocal),
    operName: 'integrateLocal',
},{
    name: 'Menu.List.Integrate.SynEcoSys.Data',
    image: require("@/assets/myanalyzes/SynEcoSys.png"),
    path: '/integrateSynEcoSysData',
    isDisabled: store.getters.isDisabled1(OPER_AUTH_TYPE.integrateSESData),
    operName: 'integrateSESData',
},{
    name: 'Menu.List.Integrate.Local.SynEcoSys.Data.Br',
    image: require("@/assets/myanalyzes/localAndSynEcoSys.png"),
    path: '/integrateLocalAndSynEcoSys',
    isDisabled: store.getters.isDisabled1(OPER_AUTH_TYPE.integrateSESAndLocal),
    operName: 'integrateSESAndLocal',
}];
export const storage = {
    USER_ANALYSIS_DATASET: store.getters.lang === 'en-US' ? 'Integrated Datasets' : '云项目  ',
    USER_SAMPLE_MATRIX: store.getters.lang === 'en-US' ? 'Uploaded files' : '上传文件',
    USER_CUSTOM_DATASET: store.getters.lang === 'en-US' ? 'Custom Datasets' : '本地项目',
    USER_WORKSPACE: store.getters.lang === 'en-US' ? 'Project files' : '项目文件',
};
export const storage1 = {
    USER_ANALYSIS_DATASET: store.getters.lang === 'en-US' ? 'Integrated Datasets' : 'Integrated Datasets',
    USER_SAMPLE_MATRIX: store.getters.lang === 'en-US' ? 'Uploaded files' : 'Uploaded files',
    USER_CUSTOM_DATASET: store.getters.lang === 'en-US' ? 'Custom Datasets' : 'Custom Datasets',
    USER_WORKSPACE: store.getters.lang === 'en-US' ? 'Project files' : 'Project files',
};

export const storageColor = ['#4C8CFA', '#FEC800', '#00D799', '#FF746C'];

export const pyscenicAlias = {
    // Method：pySCENIC
    pyscenic: 'pySCENIC',
};


export const routeKey = {
    user_cell_score_analysis_task: 'auc',
    user_enrich_one_vs_one_analysis_step1_task: 'enrich',
    user_monocle2_trajectory_analysis_task: 'monocle2',
    user_trajectory_analysis_step1_task: 'monocle3',
    user_cell_communication_analysis_step1_task: 'cellphone',
    user_cnv_infercnvpy_analysis_task: 'intercnv',
    user_cnv_infercnv_analysis_task: 'intercnvR',
    user_cnv_ith_analysis_task: 'ith',
    user_pyscenic_analysis_step1_task: 'pyscenic',
};

export const activeExploreTabs = {
    monocle2: 'six',
    monocle3: 'six',
    cellphone: 'five',
    enrich: 'four',
    auc: 'two',
    intercnv: 'nine',
    intercnvR: 'nine',
    ith: 'nine',
    pyscenic: 'tf',
};

export const routerGuidebook = {
    homepage: 'myAnalyzes',
    upload: 'importData',
    initiate: 'submitAnalysis',
};

<template>
  <div class="step-one-container">
    <div class="show-content">
      <div class="cn-wrapper" v-if="lang === 'zh-CN'">
        <div class="content">
          <h3>欢迎使用CeleLens Cloud云平台</h3>
          <p>这是一个单细胞数据在线分析平台！ <br/>
            让我们一起快速了解一下如何使用它吧。
          </p>
        </div>
        <div class="btn-wrapper">
          <el-button type="text" @click="handleSkipTutor">跳过教程</el-button>
          <el-button type="primary" class="btn-next" @click="handleNext">下一步</el-button>
        </div>
      </div>

      <div class="en-wrapper" v-else>
        <div class="cn-wrapper">
          <div class="content">
            <h3>Welcome to CeleLens Cloud platform</h3>
            <p>This is an online platform for single cell data analysis. <br/>
              Let's go through a quick tutorial.
            </p>
          </div>
          <div class="btn-wrapper">
            <el-button type="text" @click="handleSkipTutor">Skip tutorials</el-button>
            <el-button type="primary" class="btn-next" @click="handleNext">Next</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "stepOne",
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  methods: {
    // 跳过教程
    handleSkipTutor() {
      this.$emit('closeDialog');
    },
    //  下一步
    handleNext() {
      this.$emit('sendNextStep', 'two');
    },
  },
};
</script>

<style scoped lang="scss">
.step-one-container {
  padding: 10px;
  background: linear-gradient( 180deg, #E6F2FF 0%, #FFFFFF 100%);
  .show-content {
    border-radius: 8px;
    border: 1px solid rgba(151,151,151,0.26);
    padding: 30px 20px 10px 20px;
    .content {
      text-align: center;
      h3 {
        font-weight: bold;
        font-size: 20px;
        color: #000000;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        margin-top: 30px;
        margin-bottom: 50px;
        line-height: 24px;
      }
    }
    .btn-wrapper {
      position: relative;
      .btn-next {
        width: 160px;
        height: 40px;
        background: #0D65BD;
        border-radius: 20px;
        position: absolute;
        left: 48.5%;
        transform: translateX(-48.5%);
      }
    }
  }
}
</style>
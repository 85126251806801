export const degsTableCN = {
    'Degs.Table.List.Of.Cluster.Degs': '差异表达基因',
    'Degs.Table.Choose': '选择子集',
    'Degs.Table.File.Download': 'DEG file download',
    'Degs.Table.Cluster': 'cluster',
    'Degs.Table.Gene.ID': 'Gene id',
    'Degs.Table.P.Value': 'Pvalue',
    'Dega.Table.Average.Expression.Nnormalized': '平均表达量',
    'Degs.Table.Average.Expression.Rraw': '平均表达量（raw）',
    'Degs.Table.LogFC': 'LogFC',
    'Degs.Table.Pct1': 'Pct1',
    'Degs.Table.Z.Score': 'z score',
    'Degs.Table.Z.Score1': 'Z score',
    'Degs.Table.P.Val.Adj': 'Adjusted p value',
    'Degs.Table.Pct2': 'Pct2',
    'Deg.Table.Tips': '一个cluster的差异基因分析只有在存在三个或更多细胞的情况下才能进行',
    'Deg.Table.Btn.Table': '表格',
    'Deg.Table.Btn.Heatmap': '均值热图',
    'Deg.Table.Btn.Expression.Heatmap': '热图',
    'Deg.Table.Btn.Expression.Table': '平均表达表格',
    'Deg.Table.Btn.Expression.Table.Tips': '下载所选细胞群中所有基因的平均表达值的文件',
    'Deg.Table.Btn.Expression.Value': '平均表达值',
    'Deg.Table.Btn.Expression.Select.Group': '选择一个分组',
    'Deg.Table.Unfiltered.Ranked.Deg.List.Download': '未过滤差异基因列表',
    'Deg.Table.Table.Tips': '表格仅展示满足Log2FC≥0.25、Adjusted p value≤0.05、Pct1≥0.1（或Pct2≥0.1）条件的过滤后差异基因。',
};

export const degsTableUS = {
    'Degs.Table.List.Of.Cluster.Degs': 'Differential Expression Genes',
    'Degs.Table.Choose': 'Choose subset',
    'Degs.Table.File.Download': 'DEG file download',
    'Degs.Table.Cluster': 'cluster',
    'Degs.Table.Gene.ID': 'Gene id',
    'Degs.Table.P.Value': 'Pvalue',
    'Dega.Table.Average.Expression.Nnormalized': 'Average expression (normalized)',
    'Degs.Table.Average.Expression.Rraw': 'Average expression (raw)',
    'Degs.Table.LogFC': 'LogFC',
    'Degs.Table.Pct1': 'Pct1',
    'Degs.Table.Z.Score': 'z score',
    'Degs.Table.Z.Score1': 'Z score',
    'Degs.Table.P.Val.Adj': 'Adjusted p value',
    'Degs.Table.Pct2': 'Pct2',
    'Deg.Table.Tips': 'Differential gene analysis for a cluster can only be performed when there are 3 cells or more.',
    'Deg.Table.Btn.Table': 'Table',
    'Deg.Table.Btn.Heatmap': 'Average expression Heatmap',
    'Deg.Table.Btn.Expression.Heatmap': 'Expression heatmap',
    'Deg.Table.Btn.Expression.Table': 'Average expression table',
    'Deg.Table.Btn.Expression.Table.Tips': 'Download the file contatining the average expression values of all genes in the selected group.',
    'Deg.Table.Btn.Expression.Value': 'Average expression value',
    'Deg.Table.Btn.Expression.Select.Group': 'Select a group',
    'Deg.Table.Unfiltered.Ranked.Deg.List.Download': 'Unfiltered ranked DEG list download',
    'Deg.Table.Table.Tips': 'The table only displays the filtered differentially expressed genes with Log2FC ≥ 0.25, Adjusted p value ≤ 0.05, and Pct1 ≥ 0.1 (or Pct2 ≥ 0.1).',

};
import { intergrateDatasetsCN, intergrateDatasetsUS } from "./intergrateDatasets";
import { projectFilesCN, projectFilesUS } from './projectFiles';
import { uploadFileCN, uploadFileUS } from "./uploadFile";
const myAnalysesCN = {
    'My.Analyses.Intergrated.Datasets': '云项目',
    'My.Analyses.Custom.Datasets': '本地项目',
    'My.Analyses.Uploaded.Files': '上传文件',
    'My.Analyses.Result.Files': '结果文件',
    'My.Analyses.Project.Files': '项目文件',
    'My.Analyses.Delete.Tips': ' 分析数据文件会被永久删除。',
    'My.Analyses.Suspend.Tips': '分析将暂停。您可以稍后继续分析。',
    'My.Analyses.Uploaded.Delete.Tips': '文件将被永久删除',

    'My.Analyses.File.Name': '文件名',
    'My.Analyses.File.Size': '文件大小',
    'My.analyses.Format': '文件格式',
    'My.Analyses.Uploaded.On': '上传时间',
    'My.analyses.State': '状态',
    'My.analyses.Status': '状态',
    'My.Analyses.Action': '操作',
    'My.Analyses.Storage.Time': '储存时间',
    'My.Analyses.Produced.On': '完成时间',
    'My.Analyses.Project.name': '项目名称',
    'My.Analyses.Sample.ID': '样本ID',
    'My.Analyses.Submission.Time': '提交时间',
    'My.Analyses.Finish.Time': '完成时间',
    'My.Analyses.Running.Time': '运行时间',
    'My.Analyses.Details': '详情',
    'My.Analyses.Job.Name': '任务名称',

    'My.Analyses.Folder.Name': '文件夹名称',
    'My.Analysis.Storage.Warning': '您的用户空间不足，无法继续完成您的数据分析和上传请求。',
    'My.Analysis.Search.Placeholder': '搜索文件名称/项目名称/样本ID',
    'My.Analysis.Method': '方法',
    'My.Analysis.Version': '版本',
    'My.Analyses.Delete.Tips1': '要确认删除，请输入：',
    'My.Analyses.Delete.Tips2': '文件将被永久删除。',
    'My.Analyses.Delete.Title2': '删除文件',

};
const myAnalysesUS = {
    'My.Analyses.Intergrated.Datasets': 'Integrated Datasets',
    'My.Analyses.Custom.Datasets': 'Custom Datasets',
    'My.Analyses.Uploaded.Files': 'Uploaded files',
    'My.Analyses.Result.Files': 'Result files',
    'My.Analyses.Project.Files': 'Project files',
    'My.Analyses.Delete.Tips': 'Analysis data files will be deleted permanently.',
    'My.Analyses.Suspend.Tips': 'Analysis will be suspended. You can resume it later',
    'My.Analyses.Uploaded.Delete.Tips': 'Files will be deleted permanently',

    'My.Analyses.File.Name': 'File name',
    'My.Analyses.File.Size': 'File size',
    'My.analyses.Format': 'Format',
    'My.Analyses.Uploaded.On': 'Uploaded on',
    'My.analyses.State': 'State',
    'My.analyses.Status': 'Status',
    'My.Analyses.Action': 'Action',
    'My.Analyses.Storage.Time': 'Storage time',
    'My.Analyses.Produced.On': 'Produced on',
    'My.Analyses.Project.name': 'Project name',
    'My.Analyses.Sample.ID': 'SampleID',
    'My.Analyses.Submission.Time': 'Submission time',
    'My.Analyses.Finish.Time': 'Finish time',
    'My.Analyses.Running.Time': 'Running time',
    'My.Analyses.Details': 'Details',
    'My.Analyses.Job.Name': 'Job name',

    'My.Analyses.Folder.Name': 'Folder name',
    'My.Analysis.Storage.Warning': 'Due to insufficient user space，analysis and upload operations cannot proceed.',
    'My.Analysis.Search.Placeholder': 'Search file name/project name/sampleID',
    'My.Analysis.Method': 'Method',
    'My.Analysis.Version': 'Version',
    'My.Analyses.Delete.Tips1': 'To confirm deletion, please type:',
    'My.Analyses.Delete.Tips2': 'Files will be deleted permanently.',
    'My.Analyses.Delete.Title2': 'Delete files',

};

export const myMainAnalysesCN = {
    ...myAnalysesCN,
    ...intergrateDatasetsCN,
    ...projectFilesCN,
    ...uploadFileCN,
};
export const myMainAnalysesUS = {
    ...myAnalysesUS,
    ...intergrateDatasetsUS,
    ...projectFilesUS,
    ...uploadFileUS,
};
<template>
  <div class="step-one-container">
        <div v-if="lang === 'zh-CN'">
          <ul>
            <li>
              <img src="@/assets/tutorials/Homepage1.png" alt="">
              <div class="desc">
                <h3>文件管理面板</h3>
                <p>您可以在此查看文件和浏览整合后的数据集。</p>
              </div>
            </li>
            <li>
              <img src="@/assets/tutorials/Homepage2.png" alt="">
              <div class="desc">
                <h3>启动分析面板</h3>
                <p>上传矩阵文件完成后，您可以使用已上传文件、项目文件或来自SynEcoSys的数据集开始整合分析。</p>
              </div>
            </li>
            <li>
              <img src="@/assets/tutorials/Homepage3.png" alt="">
              <div class="desc">
                <h3>文件管理面板</h3>
                <p>您可以在此查看您的账户存储空间及其分布情况。</p>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <ul>
            <li>
              <img src="@/assets/tutorials/Homepage1.png" alt="">
              <div class="desc">
                <h3>File manage panel</h3>
                <p>Here you can check your files and view integrated datasets.</p>
              </div>
            </li>
            <li>
              <img src="@/assets/tutorials/Homepage2.png" alt="">
              <div class="desc">
                <h3>Initiate analysis panel</h3>
                <p>Once you've finished uploading the matrices, you can start an integrated analysis using either the uploaded files, project files, or datasets from SynEcoSys.</p>
              </div>
            </li>
            <li>
              <img src="@/assets/tutorials/Homepage3.png" alt="">
              <div class="desc">
                <h3>My data panel</h3>
                <p>Here you can view your account storage space and its distribution.</p>
              </div>
            </li>
          </ul>
        </div>
  </div>
</template>

<script>
export default {
  name: "stepOne",
  components: {},
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.step-one-container {
  ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    li {
      display: flex;
      margin-top: 25px;
      img {
        height: fit-content;
      }
      .desc {
        //margin: 0 30px;
        margin-left: 25px;
        h3 {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          white-space: nowrap;
          margin-top: 5px;
          margin-bottom: 10px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
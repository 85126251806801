// 按钮权限类别
export const OPER_AUTH_TYPE = {
    Indistinct1: 'IsShowCanonicalMarkers',
    Indistinct2: 'GeneMakerTextIndistinct',
    Indistinct3: 'CellMakerTextIndistinct',
    Search1: 'HomeGeneSearch',
    Search2: 'HomeCellSearch',
    Search3: 'HomeStudySearch',
    MultipleCompare1: 'GeneMultipleCompare',
    MultipleCompare2: 'CellMultipleCompare',
    MultipleCompare3: 'StudyMultipleCompare',

    DocumentationType1: 'DocumentationTypeCn',
    DocumentationType2: 'DocumentationTypeCom',
    AnalysisJobs1 : 'OnlyShowIntegrateSesDatasets',
    GuideBookType1: 'GuideBookTypeCn',
    GuideBookType2: 'GuideBookTypeCom',
    IsAddAgreementSigning: 'CeleLens:IsAddAgreementSigning',

    // 按钮跳转相关
    importDataUploadNowBtn: 'CeleLens:ImportData',
    integratedAnalysis: 'CeleLens:IntergrateData',
    integrateSESData: 'CeleLens:IntegrateSynEcoSysData',
    integrateSESAndLocal: 'CeleLens:integrateLocalAndSynEcoSys',
    integrateLocal: 'CeleLens:IntegrateLocalData',

    // explore部分
    Explore4: 'CeleLens:ExploreCellQuality',
    Explore5: 'CeleLens:ExploreGeneExpression',
    Explore6: 'CeleLens:ExploreCellularComposition',
    Explore7: 'CeleLens:ExploreDifferentialExpression',

    Explore1: 'CeleLens:ExploreCellInteraction',
    Explore2: 'CeleLens:ExploreTrajectoryAnalysis',
    Explore3: 'CeleLens:ExploreCNVAnalysis',
    Explore8: 'CeleLens:ExploreTFRegulation',
    Explore9: 'CeleLens:ExploreCellAnnotations',

    DownloadFreeFile: 'CeleLens:DownloadFreeFile',
};
export const uploadFileCN = {
  'My.Analyses.Ensembl.Version': '基因组版本',
  'My.Analyses.Ensembl.Version.Tips': '请确保已选择文件的物种,目前仅可检测人和小鼠的Ensembl基因组版本。',
  'My.Analyses.Project.File.Ensembl.Version.Tips': '目前仅可检测人和小鼠的Ensembl基因组版本',
  'My.Analyses.Ensembl.Overlap': '基因组覆盖率',
  'My.Analyses.Ensembl.Overlap.Tips': '覆盖率只包含蛋白质编码基因',
  'My.Analyses.Upload.File.convertGenome': '转换基因组',
  'My.Analyses.Upload.File.Convert.Genome.tips': '注意：转换存在风险。转换仅针对蛋白质编码基因，长链非编码。',
  'My.Analyses.Upload.File.uploadFile': '上传文件',
  'My.Analyses.Upload.File.recheckGenome': '重新检查基因组',
  'My.Analyses.Upload.File.convertGenome.to': '转换成',
  'files.selected': '项已选',
  'file.selected': '项已选',
  'My.Analyses.Upload.File.Edit.Species.tips': '编辑物种信息（编辑后需重新检查基因组版本）。',
};
export const uploadFileUS = {
  'My.Analyses.Ensembl.Version': 'Ensembl version',
  'My.Analyses.Ensembl.Version.Tips': 'Please ensure that the species has been selected for the file, only the Ensembl genome versions for human and mice can be detected.',
  'My.Analyses.Project.File.Ensembl.Version.Tips': 'Currently, only the Ensembl genome versions for human and mice can be detected.',
  'My.Analyses.Ensembl.Overlap': 'Ensembl overlap%',
  'My.Analyses.Ensembl.Overlap.Tips': 'Overlap includes only protein-coding genes.',
  'My.Analyses.Upload.File.convertGenome': 'Convert Genome',
  'My.Analyses.Upload.File.Convert.Genome.tips': 'Note: Conversion carries risks. Conversion is applied only to protein-coding genes, while unmatched genes such as lncRNA and pseudoRNA remain unchanged.',
  'My.Analyses.Upload.File.uploadFile': 'Upload File',
  'My.Analyses.Upload.File.recheckGenome': 'Recheck Genome',
  'My.Analyses.Upload.File.convertGenome.to': 'Convert to',
  'file.selected': ' file selected',
  'files.selected': ' files selected',
  'My.Analyses.Upload.File.Edit.Species.tips': 'Edit species information (Ensembl version needs to be rechecked manually after editing).',

};
